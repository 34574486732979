<template>
  <div v-if="isLoaded">
    <DeleteModal
      :is-delete-modal-active.sync="isDeleteModalActive"
      :items="deleteItem"
      @confirm-delete="confirmDelete($event)" />
    <EmailGroupAdd
      ref="emailGroupAdd"
      :name="nameValidate"
      :is-sidebar-active.sync="isSidebarActive"
      @add-email-group="addEmailGroup($event)" />
    <EmailGroupDetail
      :name="nameValidate"
      :is-sidebar-active.sync="isSidebarDetailActive"
      :initial-email-group="initialEmailGroup"
      :selected-item.sync="selectedItem"
      @delete-item="openDeleteModal($event)"
      @edit-email-group="editEmailGroup($event)" />
    <EmailGroupFillter
      :search.sync="filter.searchGroupName"
      :status-filter.sync="filter.status"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Add Email Group"
        @active-sidebar="activeSidebar()"
        @change="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @sort-changed="sortFilter($event)"
        @row-clicked="rowClick($event)">
        <template #cell(status)="data">
          <b-badge :variant="$statusValue(data.value)">
            {{ data.value | formatStatusData }}
          </b-badge>
        </template>
        <template #cell(groupName)="data">
          <span class="font-weight-semibold">{{ data.value }}</span>
        </template>
        <template #cell(toEmail)="data">
          <div
            v-for="(toemail, index) in data.value"
            :key="index">
            <span>{{ toemail }}</span>
          </div>
        </template>
        <template #cell(ccEmail)="data">
          <div v-if="data && data.item && data.item.ccEmail.length > 0">
            <div
              v-for="(ccemail, index) in data.item.ccEmail"
              :key="index">
              <span>{{ ccemail }}</span>
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(remark)="data">
          <div v-if="data && data.item && data.item.remark">
            {{ data.item.remark }}
          </div>
          <div v-else>
            -
          </div>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalDocs"
            :per-page="pagination.limit"
            first-number
            last-number
            class="mb-1 mt-2 mr-1"
            prev-class="prev-item"
            next-class="next-item"
            @change="showCurrentPage($event)">
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18" />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import EmailGroupFillter from '@/components/New/EmailSetting/EmailGroup/EmailGroupFillter.vue'
import EmailGroupAdd from '@/components/New/EmailSetting/EmailGroup/EmailGroupAdd.vue'
import EmailGroupDetail from '@/components/New/EmailSetting/EmailGroup/EmailGroupDetail.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeleteModal from '@/components/DeleteModal.vue'
import TableTop from '@/components/TableTop.vue'
import EmailGroupProvider from '@/resources/EmailGroupProvider'

const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    EmailGroupFillter,
    EmailGroupAdd,
    EmailGroupDetail,
    DeleteModal,
    TableTop
  },
  data () {
    return {
      isLoaded: false,
      nameValidate: 'To Email',
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isDeleteModalActive: false,
      filter: {
        searchGroupName: '',
        status: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 0
      },
      fields: [
        { key: 'groupName', label: 'Group Name', sortable: true },
        { key: 'toEmail', label: 'To Email' },
        { key: 'ccEmail', label: 'CC Email' },
        { key: 'remark', label: 'Remark' },
        { key: 'status', label: 'Status' }
      ],
      items: [],
      initialEmailGroup: {},
      selectedItem: {},
      deleteItem: {}
    }
  },
  computed: {
    page: {
      set (val) {
        this.page = val
      },
      get () {
        const page = this.$route?.query?.page
        if (!page || +page <= 0) {
          return 1
        }
        return +page
      }
    },
    limit () {
      const limit = this.$route?.query?.limit
      if (!limit || +limit <= 0) {
        return 10
      }
      return +limit
    }
  },
  async created () {
    try {
      await this.getEmailGroup()
    } finally {
      this.isLoaded = true
    }
  },
  methods: {
    fetchFilter () {
      this.setPagination(1)
      this.getEmailGroup()
    },
    openDeleteModal (val) {
      this.deleteItem = { ...val }
      this.isDeleteModalActive = true
    },
    async editEmailGroup (val) {
      try {
        const payload = { ...val }
        delete payload.id
        const { data } = await EmailGroupService.update(val.id, payload)
        await this.getEmailGroup()
        this.setInitialEmailGroupAndSelectItem(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Group has been updated'
          }
        })
      } catch (err) {
        console.error(err)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Email Group has been Failed'
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    async getEmailGroup () {
      try {
        const page = this.$route?.query?.page || this.pagination?.page || 1
        const limit = this.$route?.query?.limit || this.pagination?.limit || 10
        const data = await EmailGroupService.paginate(
          page, limit,
          this.filter
        )
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (err) {
        console.error(err)
      }
    },
    async addEmailGroup (val) {
      try {
        await EmailGroupService.create(val)
        await this.getEmailGroup()
        this.resetFormAndToggleSideBar(this.$refs.emailGroupAdd)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Record Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Group has been added'
          }
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Email Group has been Failed'
          }
        })
      }
    },
    sortFilter (ctx) {
      console.log(ctx)
    },
    async confirmDelete (val) {
      try {
        this.isSidebarDetailActive = false
        await EmailGroupService.softDelete(val.ID)
        await this.getEmailGroup()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Email Group has been deleted'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Email Group has been Failed ${error}`
          }
        })
      }
    },
    rowClick (val) {
      this.setInitialEmailGroupAndSelectItem(val)
      this.isSidebarDetailActive = true
    },
    setInitialEmailGroupAndSelectItem (val) {
      this.selectedItem = { ...val }
      this.initialEmailGroup = { ...val }
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getEmailGroup()
    },
    setPagination (page, totalDocs) {
      this.$router.replace(`email-group?page=${page}`)
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
      this.pagination.page = page || this.pagination.page
    },
    resetFormAndToggleSideBar (ref) {
      this.isSidebarActive = !this.isSidebarActive
      if (ref && ref.resetForm()) ref.resetForm()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
