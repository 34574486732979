var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"visible":_vm.isSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":function($event){return _vm.resetForm()},"change":function($event){return _vm.updateIsSidebarActive($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add New Email Group ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('ValidationObserver',{ref:"EmailGroupAddForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm()}}},[_c('TextFieldInput',{attrs:{"name":"Group Name","label":"Group Name","rules":{ required: true },"trim":""},model:{value:(_vm.emailGroup.groupName),callback:function ($$v) {_vm.$set(_vm.emailGroup, "groupName", $$v)},expression:"emailGroup.groupName"}}),_c('ValidationProvider',{ref:_vm.refName,attrs:{"name":_vm.name,"vid":_vm.name,"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"To Email","state":_vm.$getValidationState(validationContext)}},[_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","taggable":"","close-on-select":false,"options":_vm.emailOptions,"input-id":"email-to"},on:{"input":function($event){return _vm.parseEmails('to', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.emailGroup.toEmail),callback:function ($$v) {_vm.$set(_vm.emailGroup, "toEmail", $$v)},expression:"emailGroup.toEmail"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.$getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"CC Email"}},[_c('v-select',{staticClass:"flex-grow-1 email-to-selector",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"name","taggable":"","close-on-select":false,"rules":{ required: true },"options":_vm.emailOptions,"input-id":"email-to"},on:{"input":function($event){return _vm.parseEmails('cc', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var name = ref.name;
return [_c('b-avatar',{staticClass:"border border-white",attrs:{"size":"sm","src":avatar}}),_c('span',{staticClass:"ml-50"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.emailGroup.ccEmail),callback:function ($$v) {_vm.$set(_vm.emailGroup, "ccEmail", $$v)},expression:"emailGroup.ccEmail"}})],1),_c('TextFieldInput',{attrs:{"name":"remark","label":"Remark","rules":{ required: false },"trim":""},model:{value:(_vm.emailGroup.remark),callback:function ($$v) {_vm.$set(_vm.emailGroup, "remark", $$v)},expression:"emailGroup.remark"}}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2 btn-submit",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-submit",attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }