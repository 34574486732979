<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Email Group
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="EmailGroupAddForm">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="emailGroup.groupName"
            name="Group Name"
            label="Group Name"
            :rules="{ required: true }"
            trim />
          <ValidationProvider
            #default="validationContext"
            :ref="refName"
            :name="name"
            :vid="name"
            :rules="{ required: true }">
            <b-form-group
              label="To Email"
              :state="$getValidationState(validationContext)">
              <v-select
                v-model="emailGroup.toEmail"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                taggable
                class="flex-grow-1 email-to-selector"
                :close-on-select="false"
                :options="emailOptions"
                input-id="email-to"
                @input="parseEmails('to', $event)">
                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50"> {{ name }}</span>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="$getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
          <b-form-group
            label="CC Email">
            <v-select
              v-model="emailGroup.ccEmail"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              label="name"
              taggable
              class="flex-grow-1 email-to-selector"
              :close-on-select="false"
              :rules="{ required: true }"
              :options="emailOptions"
              input-id="email-to"
              @input="parseEmails('cc', $event)">
              <template #option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>

              <template #selected-option="{ avatar, name }">
                <b-avatar
                  size="sm"
                  class="border border-white"
                  :src="avatar"
                />
                <span class="ml-50"> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
          <TextFieldInput
            v-model="emailGroup.remark"
            name="remark"
            label="Remark"
            :rules="{ required: false }"
            trim />
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'

export default {
  components: {
    TextFieldInput
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      to: [],
      cc: [],
      initialEmailGroup: {
        groupName: '',
        toEmail: [],
        ccEmail: [],
        remark: ''
      },
      emailGroup: {
        groupName: '',
        toEmail: [],
        ccEmail: [],
        remark: ''
      }
    }
  },
  computed: {
    emailOptions () {
      return [...new Set(this.emailToOptions)]
    },
    refName () {
      return `validation-provider-${this.name}`
    }
  },
  methods: {
    isValidEmail (email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      return emailPattern.test(email)
    },
    parseEmails (type, data) {
      const { length } = data
      const email = data[length - 1]
      const emailArray = email.split(/[;,]+/)
      const { emailGroup } = this
      if (type === 'to') {
        if (Array.isArray(data) && Array.isArray(emailGroup.toEmail) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            emailGroup.toEmail.pop()
            emailGroup.toEmail.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.emailGroup.toEmail) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.emailGroup.toEmail = [...new Set(newEmail)]
      }
      if (type === 'cc') {
        if (Array.isArray(data) && Array.isArray(emailGroup.ccEmail) && typeof email === 'string' && email) {
          if (emailArray.length > 0) {
            emailGroup.ccEmail.pop()
            emailGroup.ccEmail.push(...emailArray)
          }
        }

        const newEmail = []
        for (const item of this.emailGroup.ccEmail) {
          if (this.isValidEmail(item)) {
            newEmail.push(item)
          }
        }

        this.emailGroup.ccEmail = [...new Set(newEmail)]
      }
    },
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-email-group', this.emailGroup)
    },
    resetForm () {
      this.emailGroup = { ...this.initialEmailGroup }
      this.$refs.EmailGroupAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
